import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import TabList from "./TabList";
import TabItem from "./TabItem";
import { Avatar, Col, FlexboxGrid, HStack, List, Row } from "rsuite";
import { Paragraph, ParagraphBold } from "../Typography";
import ButtonBorder from "../Buttons/ButtonBorder";
import { FaComment, FaInfo, FaRankingStar, FaUserLarge } from "react-icons/fa6";
import { Modals } from "../../components";
import { Modals as Modal, useUiStore } from "../../stores/uiStore";
import theme from "../../theme";
import { useWatchPresenter } from "../../pages/dashboard/watch/presenter";
import { id } from "inversify";
import { useClassStore } from "../../util/useClassStore";
import { useUserStore } from "../../stores/userStore";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { FaComments } from "react-icons/fa";
import { QRCode, Space } from "antd";
import ListQuetion from "../list/ListQuetionsLesson";
import { MdEmail, MdLock, MdLockPerson, MdMailLock } from "react-icons/md";
import TextInputLarge from "../Inputs/TextInputLarge";
import { ButtonGroup } from "../../pages/dashboard/style";
import Button from "../Buttons/Button";
import { useUserPresenter } from "../../pages/dashboard/user/presenter";
interface TabWatchProps {
  courseId: string;
  itemModuleId?: string;
}
const TabConfig = observer(() => {
  const { setModal } = useUiStore();
  const { user } = useUserStore();
  const { setCourseQuetion, setItemModuleQuetion, questionList } =
    useWatchPresenter();
  const { setFormValue, ChangePasswordUser } = useUserPresenter();
  return (
    <>
      <TabList activeTabIndex={0}>
        <TabItem label="Alterar email de acesso." icon={<MdMailLock />}>
          <Content>
            <ParagraphBold size="20px" style={{ marginBottom: 20 }}>
              <MdMailLock size={24} />
              {"  "} Alterar email de acesso.
            </ParagraphBold>
            <Paragraph size="14px">
              A alteração do e-mail de acesso permite atualizar o endereço de
              e-mail vinculado à sua conta. Esse processo pode envolver a
              confirmação do novo e-mail e, em alguns casos, a verificação da
              identidade para garantir a segurança da conta.
            </Paragraph>
            <Row className="show-grid" style={{ marginTop: 17 }}>
              <Col xs={24} lg={12} xxl={12} md={24}>
                <ParagraphBold>Email</ParagraphBold>
                <TextInputLarge
                  defaultValue={user?.email}
                  placeholder="Email :"
                  name="email"
                  onChange={setFormValue}
                  icon={
                    <MdEmail size={20} color="#999" style={{ margin: 14 }} />
                  }
                />
              </Col>
              <Col xs={24} lg={12} xxl={12} md={24}>
                <ParagraphBold>Palavra passe</ParagraphBold>
                <TextInputLarge
                  placeholder="Palavra passe :"
                  type="password"
                  name="password"
                  onChange={setFormValue}
                  icon={
                    <MdLock size={20} color="#999" style={{ margin: 14 }} />
                  }
                />
              </Col>
            </Row>
            <ButtonGroup>
              <Button
                label="Salvar Informações"
                width="30"
                color={theme.BACKGROUND.BLUE}
              />
            </ButtonGroup>
          </Content>
        </TabItem>
        <TabItem label="Alterar palavra passe" icon={<MdLockPerson />}>
          <Content>
            <ParagraphBold size="20px" style={{ marginBottom: 20 }}>
              <MdLockPerson size={20} />
              {"  "} Alterar palavra passe.
            </ParagraphBold>
            <Paragraph size="14px">
              A alteração da palavra-passe permite atualizar a senha da sua
              conta para garantir mais segurança. O processo pode exigir a senha
              atual e a criação de uma nova senha forte.
            </Paragraph>
            <Row className="show-grid" style={{ marginTop: 17 }}>
              <Col xs={24} lg={12} xxl={12} md={24}>
                <ParagraphBold>Palavra passe actual</ParagraphBold>
                <TextInputLarge
                  type="password"
                  name="password"
                  onChange={setFormValue}
                  placeholder="Digite palavra passe actual :"
                  icon={
                    <MdEmail size={20} color="#999" style={{ margin: 14 }} />
                  }
                />
              </Col>
              <Col xs={24} lg={12} xxl={12} md={24}>
                <ParagraphBold>Nova palavra passe</ParagraphBold>
                <TextInputLarge
                  name="new_password"
                  onChange={setFormValue}
                  placeholder="Digite a nova palavra passe :"
                  icon={
                    <MdLock size={20} color="#999" style={{ margin: 14 }} />
                  }
                />
              </Col>
              <Col xs={24} lg={12} xxl={12} md={24}>
                <ParagraphBold>Confirmar nova palavra passe</ParagraphBold>
                <TextInputLarge
                  placeholder="Confirme a nova palavra passe :"
                  name="confirm_password"
                  onChange={setFormValue}
                  icon={
                    <MdLock size={20} color="#999" style={{ margin: 14 }} />
                  }
                />
              </Col>
              <Col xs={24} lg={12} xxl={12} md={24}>
                <ButtonGroup style={{ marginTop: 24 }}>
                  <Button
                    label="Salvar Informações"
                    width="100"
                    onClick={ChangePasswordUser}
                    color={theme.BACKGROUND.BLUE}
                  />
                </ButtonGroup>
              </Col>
            </Row>
          </Content>
        </TabItem>
      </TabList>
      <Modals.AddQuetionModal />
    </>
  );
});
export default TabConfig;
const Content = styled.div`
  width: 100%;
  margin: 60px 0px;
`;
const Quetions = styled.nav`
  width: 100%;
`;

const TabListUl = styled.ul``;
