import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";
import { device } from "../../config/devices";
import { sanitizeForId } from "../../util/stringUtils";
export interface TabProps {
  label: string;
  icon?: JSX.Element;
  children: ReactNode;
}
export interface TabListProps {
  activeTabIndex: number;
  children: ReactElement<TabProps> | ReactElement<TabProps>[];
}
const TabItem: React.FC<TabProps> = ({ label, children }) => (
  <TabPanel
    role="tabpanel"
    aria-labelledby={`tab-${sanitizeForId(label)}`}
    id={`panel-${sanitizeForId(label)}`}
  >
    {children}
  </TabPanel>
);
export default TabItem;

const TabPanel = styled.nav``;

const TabList = styled.nav``;
