import React from "react";
import { observer } from "mobx-react-lite";
import Modal from "./ModalWrapper";
import { useUiStore, Modals } from "../../stores/uiStore";
import { styled } from "styled-components";
import { Col, Progress, Row, Tabs } from "rsuite";
import { useCoursePresenter } from "../../pages/dashboard/instrutor/cursos/presenter";
import { Paragraph, ParagraphBold } from "../Typography";
import TextInputLarge from "../Inputs/TextInputLarge";
import {
  MdEdit,
  MdLink,
  MdOutlineAccessTimeFilled,
  MdOutlineFactCheck,
  MdOutlineOndemandVideo,
} from "react-icons/md";
import { useQuill } from "react-quilljs";
import Quill from "quill";
import TextArea from "../Inputs/TextArea";
import { useWatchPresenter } from "../../pages/dashboard/watch/presenter";
import ModalDefault from "./ModalDefaultWrapper";
import { InfoCardCourse } from "../../pages/front/style";
import { FaFilm } from "react-icons/fa6";
import Loadding from "../loadding/page";
import { useUserStore } from "../../stores/userStore";
import { Link } from "react-router-dom";

const InfoCourseModal = observer(() => {
  const ui = useUiStore();
  const { setModal } = ui;
  const {
    loadding,
    course,
    moduleList,
    getPercentVideoViewed,
    getQtdAula,
    videoViewedList,
  } = useCoursePresenter();
  const { user } = useUserStore();
  const percent =
    videoViewedList && moduleList
      ? Math.round(
          getPercentVideoViewed(
            videoViewedList.length,
            getQtdAula(moduleList as IModule[])
          )
        )
      : 0;
  const color = percent === 100 ? "#52c41a" : "#3385ff";
  return (
    <ModalDefault
      isOpened={ui[Modals.INFO_COURSE_MODAL]}
      buttonLabel="ENVIAR"
      title="INFORMAÇÕES DE APRENDIZADO"
      size="lg"
      loadding={loadding}
      onClose={() => {
        setModal(Modals.INFO_COURSE_MODAL, false);
      }}
      onSubmit={() => {}}
    >
      <FormWrapper>
        {moduleList && videoViewedList ? (
          <>
            <ParagraphBold size="15px" style={{ marginBottom: 0 }}>
              Informações sobre o seu progresso no curso.
            </ParagraphBold>
            <Paragraph size="13px">
              <Link to={""}>
                <strong>{user?.name}</strong>
              </Link>
              , Acompanhe seu progresso no <strong>{course?.titulo}</strong> e
              garanta seu certificado, que estará disponível assim que concluir
              todas as etapas do curso.
            </Paragraph>
            <Progress.Line percent={percent} strokeColor={color} />
            <Row>
              <Col xl={6}>
                <CardInfoCourse>
                  <MdOutlineOndemandVideo size={45} color="#999" />{" "}
                  <Paragraph size="14px" style={{ textAlign: "center" }}>
                    {moduleList && <strong>{moduleList.length}</strong>} Módulos
                  </Paragraph>
                </CardInfoCourse>
              </Col>
              <Col xl={6}>
                <CardInfoCourse>
                  <FaFilm size={45} color="#999" /> <br />
                  <Paragraph size="14px" style={{ textAlign: "center" }}>
                    <strong>{getQtdAula(moduleList as IModule[])}</strong> Aulas
                  </Paragraph>
                </CardInfoCourse>
              </Col>
              <Col xl={6}>
                <CardInfoCourse>
                  <MdOutlineFactCheck size={45} color="#999" />{" "}
                  <Paragraph size="14px" style={{ textAlign: "center" }}>
                    {videoViewedList && (
                      <strong>{videoViewedList.length}</strong>
                    )}{" "}
                    Aulas Concluídas
                  </Paragraph>
                </CardInfoCourse>
              </Col>
              <Col xl={6}>
                <CardInfoCourse>
                  <MdOutlineAccessTimeFilled size={45} color="#999" />
                  <Paragraph size="14px" style={{ textAlign: "center" }}>
                    2:40:00
                  </Paragraph>
                </CardInfoCourse>
              </Col>
            </Row>
          </>
        ) : (
          <Loadding />
        )}
      </FormWrapper>
    </ModalDefault>
  );
});
export default InfoCourseModal;
const FormWrapper = styled.div`
  min-height: 140px;
  padding: 10px;
`;
const CardInfoCourse = styled.div`
  border: 1px solid #e0e0e0;
  width: 98%;
  padding: 40px 0;
  background-color: #f4f4f4;
  min-height: 50px;
  text-align: center;
`;
