import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useUiStore, Modals, Drawers } from "../../stores/uiStore";
import {
  FaUserLarge,
  FaLock,
  FaSquareCheck,
  FaCirclePlay,
} from "react-icons/fa6";
import { styled } from "styled-components";
import Drawer from "./DrawerWrapper";
import {
  Accordion,
  Avatar,
  Content,
  FlexboxGrid,
  RadioTile,
  RadioTileGroup,
  Stack,
} from "rsuite";
import { COLORS } from "../../config/colors";
import { Paragraph, ParagraphBold, Title } from "../Typography";
import TextInput from "../Inputs/TextInput";
import { device } from "../../config/devices";
import Button from "../Buttons/Button";
import { Link, useNavigate } from "react-router-dom";
import theme from "../../theme";
import { useAuthPresenter } from "../../pages/front/presenter";
import { useUserStore } from "../../stores/userStore";
import { logo } from "../../images";
import { useWatchPresenter } from "../../pages/dashboard/watch/presenter";
import { useCoursePresenter } from "../../pages/dashboard/instrutor/cursos/presenter";
import { FaPlay } from "react-icons/fa";
interface HeaderProps {
  i: number;
  title: string;
  subtitle: string;
  item_module: number;
}
const Header = (props: HeaderProps) => {
  const { i, title, subtitle, item_module, ...rest } = props;
  return (
    <Stack {...rest} spacing={10} alignItems="flex-start">
      <Stack spacing={2} direction="column" alignItems="flex-start">
        <ParagraphBold size="13px">
          {i}
          {" - "}
          {title}
        </ParagraphBold>
        <Paragraph color="#555">
          {item_module} Aulas {" - "} 00:00:00
        </Paragraph>
      </Stack>
    </Stack>
  );
};
const GradeCurricular: React.FC = observer(() => {
  const ui = useUiStore();
  const { setDrawer } = ui;
  const {
    moduleList,
    item_module,
    videoViewedList,
    resetStoreValuesItemModule1,
    getItemModuleId,
    updateLastVideoViewed,
    createVideoViewed,
    checkVideoViewed,
  } = useCoursePresenter();
  return (
    <>
      <Drawer
        isOpened={ui[Drawers.GRADE_CURRICULAR_DRAWER]}
        onClose={() => {
          setDrawer(Drawers.GRADE_CURRICULAR_DRAWER, false);
        }}
        loadding={false}
        onSubmit={() => {}}
      >
        <Container>
          <ParagraphBold size="14px">Grade curricular do curso</ParagraphBold>
          <Paragraph size="13px">
            Lista organizada dos temas e conteúdos abordados ao longo do curso,
            estruturados para promover um aprendizado progressivo .
          </Paragraph>
          {moduleList && videoViewedList && (
            <Accordion defaultActiveKey={1}>
              {moduleList.map((module, i) => (
                <Accordion.Panel
                  header={
                    <Header
                      i={i + 1}
                      title={module.title as string}
                      subtitle="00:00:00"
                      item_module={module.items_module?.length as number}
                    />
                  }
                  eventKey={i + 1}
                >
                  {module.items_module && (
                    <ContentAccordion>
                      {module.items_module.map((item, i) => (
                        <Link
                          to={`/dashboard/watch/${item.video_external_id}`}
                          onClick={() => {
                            resetStoreValuesItemModule1();
                            createVideoViewed(item);
                            getItemModuleId(item.video_external_id as string);
                            updateLastVideoViewed(
                              item.video_external_id as string
                            );
                            setDrawer(Drawers.GRADE_CURRICULAR_DRAWER, false);
                          }}
                          key={i}
                          style={{ textDecoration: "none" }}
                        >
                          <ItemAccordion>
                            <FlexboxGrid
                              justify="space-between"
                              style={{ margin: 8 }}
                            >
                              <FlexboxGrid.Item colspan={2}>
                                {item_module?.id == item.id ? (
                                  <FaCirclePlay
                                    size={19}
                                    color={theme.BACKGROUND.GREEN}
                                  />
                                ) : (
                                  <>
                                    <FaSquareCheck
                                      color={
                                        checkVideoViewed(item)
                                          ? theme.BACKGROUND.BLUE
                                          : "#999"
                                      }
                                      size={19}
                                    />
                                  </>
                                )}
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={18}>
                                <Paragraph
                                  size="13px"
                                  style={{ textAlign: "left" }}
                                >
                                  Aula {i + 1} - {item.titulo}
                                </Paragraph>
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={4}>
                                <Paragraph>
                                  <FaPlay color="#999" /> {item.time}
                                </Paragraph>
                              </FlexboxGrid.Item>
                            </FlexboxGrid>
                          </ItemAccordion>
                        </Link>
                      ))}
                    </ContentAccordion>
                  )}
                </Accordion.Panel>
              ))}
            </Accordion>
          )}
        </Container>
      </Drawer>
    </>
  );
});
export default GradeCurricular;
const Container = styled.div`
  width: 100%;
  margin-top: 0px;
  @media ${device.mobileL} {
    position: absolute;
    width: 58%;
    margin-left: 3%;
    left: 0;
    top: 60px;
    margin-top: 40px;
  }
`;
const ContentAccordion = styled.div`
  width: 100%;
  margin-top: 0px;
  @media ${device.mobileL} {
  }
`;
const ItemAccordion = styled.div`
  width: 100%;
  padding: 5px 0px;
  border-top: 1px solid #e9e9e9;
  margin-top: 0px;
  @media ${device.mobileL} {
  }
`;
