import React from "react";
import { observer } from "mobx-react-lite";
import Modal from "./ModalWrapper";
import { useUiStore, Modals } from "../../stores/uiStore";
import { styled } from "styled-components";
import { Rate, Tabs } from "rsuite";
import { useCoursePresenter } from "../../pages/dashboard/instrutor/cursos/presenter";
import { Paragraph, ParagraphBold } from "../Typography";
import TextInputLarge from "../Inputs/TextInputLarge";
import { MdEdit, MdLink } from "react-icons/md";
import { useQuill } from "react-quilljs";
import Quill from "quill";
import TextArea from "../Inputs/TextArea";
import { useWatchPresenter } from "../../pages/dashboard/watch/presenter";
import ModalDefault from "./ModalDefaultWrapper";
import theme from "../../theme";
import Button from "../Buttons/Button";
import Loadding from "../loadding/page";

const RateLessonModal = observer(() => {
  const textStyle = {
    verticalAlign: "top",
    lineHeight: "42px",
    display: "inline-block",
  };

  const texts: { [key: number]: string } = {
    0: "",
    1: "Inútel",
    2: "Pobre",
    3: "Bom",
    4: "Muito Bom",
    5: "Excelente",
  };
  const ui = useUiStore();
  const { setModal } = ui;
  const [hoverValue, setHoverValue] = React.useState(0);
  const [changeValue, setChangeValue] = React.useState(0);
  const {
    rate,
    loadding,
    loaddingRate,
    setFormRateLessonValue,
    createRateLesson,
    setTextareaValue,
  } = useCoursePresenter();
  return (
    <ModalDefault
      isOpened={ui[Modals.RATE_LESSON_MODAL]}
      buttonLabel="ENVIAR"
      title="AVALIAR AULA"
      loadding={loadding}
      onClose={() => {
        setModal(Modals.RATE_LESSON_MODAL, false);
      }}
    >
      <FormWrapper>
        {loadding ? (
          <Loadding />
        ) : (
          <>
            <Paragraph size="14px" style={{ marginBottom: 10 }}>
              A sua avaliação é essencial para aprimorarmos a qualidade dos
              nossos serviços, com a sua opinião, podemos identificar
              oportunidades de melhoria e oferecer uma experiência ainda mais
              satisfatória.
            </Paragraph>
            {rate && rate?.id ? (
              <>
                <Rate
                  readOnly
                  defaultValue={Number(rate.rate)}
                  color="yellow"
                />{" "}
                <span style={textStyle}>{texts[hoverValue]}</span>
              </>
            ) : (
              <>
                {!loaddingRate ? (
                  <>
                    {" "}
                    <Rate
                      defaultValue={0}
                      size="lg"
                      color="yellow"
                      onChangeActive={setHoverValue}
                      onChange={setFormRateLessonValue}
                    />{" "}
                    <span style={textStyle}>{texts[hoverValue]}</span>
                  </>
                ) : (
                  <>
                    <ParagraphBold size="13px">
                      Elabore um comentário avaliativo que ofereça sugestões
                      construtivas para o aprimoramento dos próximos produtos.
                    </ParagraphBold>
                    <TextArea
                      name="comment"
                      onChange={setTextareaValue}
                      placeholder="Escrever comentário ..."
                    ></TextArea>
                    <Button
                      loading={loadding}
                      label="ENVIAR AVALIAÇÃO"
                      onClick={createRateLesson}
                      color={theme.BACKGROUND.BLUE}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </FormWrapper>
    </ModalDefault>
  );
});
export default RateLessonModal;
const FormWrapper = styled.div`
  min-height: 140px;
  padding: 10px;
`;
