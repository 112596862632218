import { observer } from "mobx-react-lite";
import FrontLayout from "../../../layouts/FrontLayout";
import {
  H1,
  Paragraph,
  ParagraphBold,
  Subtitle,
} from "../../../components/Typography";
import styled from "styled-components";
import { bg, instrutor, main, rocket } from "../../../images";
import theme from "../../../theme";
import { device } from "../../../config/devices";
import { Modals as Modal, useUiStore } from "../../../stores/uiStore";
import {
  FaBriefcase,
  FaChalkboardTeacher,
  FaChartPie,
  FaDesktop,
  FaDraftingCompass,
  FaMoneyBillAlt,
  FaRocket,
  FaServer,
  FaUserGraduate,
  FaUserTie,
} from "react-icons/fa";
import {
  ButtonPayCourse,
  CardCourse,
  CardImage,
  ContentCourse,
  ImageCardCourse,
  InfoCardCourse,
  Section,
} from "../style";

import loader from "../../../assets/home.json";
import Carousel from "react-multi-carousel";
import { useCoursePresenter } from "../../dashboard/instrutor/cursos/presenter";
import { Col, Rate, Row } from "rsuite";
import { FaCartShopping } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import Loadding from "../../../components/loadding/page";
import Button from "../../../components/Buttons/Button";
import ButtonBorder from "../../../components/Buttons/ButtonBorder";
import Lottie from "react-lottie";
interface ICategorias {
  label?: string;
  icon?: JSX.Element;
  value?: number;
  descript?: string;
  color?: string;
  path?: string;
}
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const categorias: ICategorias[] = [
  {
    label: "Desenvolvimento & programação",
    value: 1,
    icon: <FaDesktop color="#e61336" size={24} style={{ marginTop: 5 }} />,
    descript: "",
    color: "#e61336",
    path: "desenvolvimeno-programacao",
  },
  {
    label: "Acadêmico",
    value: 2,
    icon: <FaUserGraduate size={25} style={{ marginTop: 5 }} color="#f5d611" />,
    descript: "",
    color: "#14ba7d",
    path: "academico",
  },
  {
    label: "Administração Pública",
    value: 2,
    icon: <FaBriefcase size={24} style={{ marginTop: 5 }} color="#09e3b0" />,
    descript: "",
    color: "#14ba7d",
    path: "administracao-publica",
  },
  {
    label: "Finanças & contabilidade",
    value: 2,
    icon: <FaChartPie color="#e36f09" size={24} style={{ marginTop: 5 }} />,
    descript: "",
    color: "#14ba7d",
    path: "financa-contabilidade",
  },
  {
    label: "Produtividade no escritório",
    value: 2,
    icon: <FaRocket color="#55f511" size={24} style={{ marginTop: 5 }} />,
    descript: "",
    color: "#14ba7d",
    path: "produtividade-no-escritorio",
  },
  {
    label: "Desenvolvimento pessoal",
    value: 2,
    icon: <FaUserTie color="#11b1f5" size={24} style={{ marginTop: 5 }} />,
    descript: "",
    color: "#14ba7d",
    path: "desenvolvimento-pessoal",
  },
];
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 954 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 954, min: 480 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 480, min: 0 },
    items: 1,
  },
};
const Home: React.FC = observer(() => {
  const { resetStoreValues, courseList, getFormatCurrent, loadding } =
    useCoursePresenter();
  const navigate = useNavigate();
  return (
    <>
      <FrontLayout>
        <SectionMain>
          <Content>
            <Row className="show-grid" style={{ padding: 0, margin: 0 }}>
              <Col
                lg={12}
                xl={10}
                xxl={10}
                md={24}
                sm={24}
                style={{ padding: 0, margin: 0 }}
              >
                <Lottie options={defaultOptions} width="100%" />
              </Col>
              <Col
                lg={12}
                xl={14}
                xxl={14}
                md={24}
                sm={24}
                style={{ padding: 0, margin: 0 }}
              >
                <ContainerMain>
                  <H1 color={theme.BACKGROUND.BLACK}>
                    Comece sua trajetória de aprendizado na{" "}
                    <Link to={"/"}>Rozacademy</Link> !
                  </H1>
                  <Subtitle size="25px" color="#555" style={{ marginTop: 15 }}>
                    Aprenda no seu próprio ritmo, sem prazos ou limitações, e
                    alcance seus objetivos com autonomia e flexibilidade.
                  </Subtitle>
                  <Button
                    label="COMEÇAR AGORA"
                    width="45"
                    color={theme.BACKGROUND.BLUE}
                  />
                  <ButtonBorder
                    label="ENSINAR NA ROZACADEMY"
                    width="45"
                    color={theme.BACKGROUND.BLUE}
                  />
                </ContainerMain>
              </Col>
            </Row>
          </Content>
        </SectionMain>
        <Section>
          {loadding && <Loadding />}
          {courseList && (
            <ContentCourse>
              <ParagraphBold size="25px">
                Nossas principais sugestões para você.
              </ParagraphBold>
              <Paragraph style={{ marginBottom: 15 }} size="16px">
                Aprenda no conforto de casa, sem um cronograma fixo, com um
                método simples e intuitivo. Aqui, você define o seu ritmo!{" "}
              </Paragraph>
              <Carousel responsive={responsive}>
                {courseList.map((item, i) => (
                  <CardCourse>
                    <Link
                      to={`/cursos/${item?.id}/detail`}
                      style={{ textDecoration: "none" }}
                      onClick={() => {
                        resetStoreValues();
                      }}
                    >
                      <ImageCardCourse src={item?.img_url} />
                      <InfoCardCourse>
                        <ParagraphBold size="14px" style={{ marginRight: 10 }}>
                          {item?.titulo}
                        </ParagraphBold>
                        <Paragraph>{item?.users?.name}</Paragraph>
                        <Paragraph size="11px">
                          <strong>2.7</strong>{" "}
                          <Rate defaultValue={4} size="xs" color="yellow" />
                          (43)
                        </Paragraph>
                        <ParagraphBold color={theme.BACKGROUND.GREEN}>
                          {item?.price
                            ? `${getFormatCurrent(item?.price as number)} Kz`
                            : "Grátis"}
                        </ParagraphBold>
                        <ButtonPayCourse
                          onClick={() => {
                            navigate("/cursos/" + item?.id + "/detail");
                          }}
                        >
                          <FaCartShopping size={14} /> Adquirir Agora
                        </ButtonPayCourse>
                      </InfoCardCourse>
                    </Link>
                  </CardCourse>
                ))}
              </Carousel>
            </ContentCourse>
          )}
        </Section>
      </FrontLayout>
    </>
  );
});
export default Home;
const SectionMain = styled.section`
  width: 100%;
  min-height: 130px;
  background: url(${bg});
  background-position: 100% 50%;
  justify-content: center;
`;
const Content = styled.div`
  width: 100%;
  min-height: 130px;
  background-color: rgba(255, 255, 255, 0.9);
`;
const ContainerMain = styled.div`
  margin: 5% 10px;
  padding: 20px;
  @media ${device.tablet} {
    margin: 5% 10px;
    padding: 20px;
  }

  @media ${device.laptop} {
    padding: 14px;
    margin: 10% 15px;
  }
`;

const ImageMain = styled.img`
  width: 100%;
  background: transparent;
`;
