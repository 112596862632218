import { observer } from "mobx-react-lite";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Avatar, Col, Dropdown, FlexboxGrid, Row, Tabs } from "rsuite";
import styled from "styled-components";
import React, { useState } from "react";
import { useCoursePresenter } from "../instrutor/cursos/presenter";
import Theme from "../../../theme";
import { FaAngleLeft, FaListOl, FaRankingStar } from "react-icons/fa6";
import {
  Modals as Modal,
  Drawers as Drawer,
  useUiStore,
} from "../../../stores/uiStore";
import { Drawers, Modals } from "../../../components";
import { useUserPresenter } from "../user/presenter";
import { FaComments, FaInfoCircle } from "react-icons/fa";
import { useUserStore } from "../../../stores/userStore";
import { Layout, theme } from "antd";
import { useWatchPresenter } from "./presenter";
const Watch: React.FC = observer(() => {
  const { id_course } = useParams();

  const navigate = useNavigate();
  const ui = useUiStore();
  const { setDrawer } = ui;
  const { setModal } = ui;
  const { questionList, setCourseQuetion, setItemModuleQuetion } =
    useWatchPresenter();
  const {
    loadding,
    purchased,
    resetStorePurchased,
    setVideoId,
    getItemModuleId,
    updatePurchased,
    updateLastVideoViewed,
    createVideoViewed,
    resetStoreValuesItemModule,
    resetStoreValuesItemModule1,
    item_module_list,
    item_module,
    getCourseId,
    course,
    resetStoreRate,
    getRateLessonByUserPeerLesson,
    getItemModuleByCourseId,
  } = useCoursePresenter();
  const { user } = useUserStore();
  React.useEffect(() => {
    if (id_course) {
      getItemModuleId(id_course as string);
    } else {
      resetStoreValuesItemModule1();
      resetStoreValuesItemModule();
      getItemModuleByCourseId(purchased?.id_course as string);
      if (item_module_list) {
        setVideoId(item_module_list[0].video_external_id as string);
        updatePurchased();
        createVideoViewed(item_module_list[0] as IITEM_MODULE);
        updateLastVideoViewed(item_module_list[0].video_external_id as string);
        navigate(`/dashboard/watch/${item_module_list[0].video_external_id}`);
      }
      // resetStoreItemModule();
    }
  }, [id_course]);
  return (
    <ContentPlayer>
      <MenuPlayer>
        <BackButton
          onClick={() => {
            resetStorePurchased();
            navigate(`/dashboard/user/meu-percurso`);
          }}
        >
          <FaAngleLeft />
        </BackButton>
        <Menu>
          <button
            onClick={() => {
              setDrawer(Drawer.GRADE_CURRICULAR_DRAWER, true);
            }}
          >
            <FaListOl />
          </button>
          <button
            onClick={() => {
              if (item_module) {
                resetStoreRate();
                getRateLessonByUserPeerLesson();
              }
              setModal(Modal.RATE_LESSON_MODAL, true);
            }}
          >
            <FaRankingStar />
          </button>
          <button
            onClick={() => {
              if (item_module) {
                setCourseQuetion(item_module.course as string);
                setItemModuleQuetion(item_module.id as string);
              }
              setModal(Modal.QUETION_LIST_MODAL, true);
            }}
          >
            <FaComments />
          </button>
          <button
            onClick={() => {
              if (item_module) {
                getCourseId(item_module.course as string);
                setCourseQuetion(item_module.course as string);
                setItemModuleQuetion(item_module.id as string);
              }
              setModal(Modal.INFO_COURSE_MODAL, true);
            }}
          >
            <FaInfoCircle />
          </button>
        </Menu>
      </MenuPlayer>
      <Player>
        {item_module && (
          <div
            style={{
              position: "relative",
              paddingTop: "54.25%",
              marginBottom: 10,
            }}
          >
            <iframe
              id={`panda-${item_module.video_external_id}`}
              src={item_module.video_player}
              style={{
                border: "none",
                position: "absolute",
                top: 0,
                left: 0,
              }}
              allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
              allowFullScreen={true}
              width="100%"
              height="100%"
            />
          </div>
        )}
      </Player>
      <Drawers.GradeCurricular />
      <Modals.QuetionListModal />
      <Modals.InfoCourseModal />
      <Modals.RateLessonModal />
    </ContentPlayer>
  );
});

export default Watch;
interface IProps {
  backgroundImage?: string;
}
export const ContentPlayer = styled.div`
  width: 100%;
  margin: 0;
  margin-top: -20px;
  padding: 0;
  background-color: #000;
  display: flex;
`;
export const BackButton = styled.button`
  width: 100%;
  height: 80px;
  margin: 0;
  font-size: 40px;
  padding: 10px 0;
  color: #999;
  background-color: transparent;
  &:hover {
    color: ${Theme.BACKGROUND.WHITE};
  }
`;
export const Menu = styled.div`
  margin-top: 40px;
  button {
    width: 100%;
    height: 70px;
    border: 0;
    color: #999;
    background: transparent;
    font-size: 35px;
    margin-bottom: 10px;
    &:hover {
      background-color: ${Theme.BACKGROUND.BLUE};
      color: #fff;
    }
  }
`;
export const Player = styled.div`
  width: 85%;
  margin: 0 auto;
`;

const MenuPlayer = styled.div`
  width: 8%;
  height: 100vh;
  background-color: #000;
  min-height: 14px;
`;
