import { observer } from "mobx-react-lite";
import FrontLayout from "../../../layouts/FrontLayout";
import styled from "styled-components";
import { device } from "../../../config/devices";

import {
  ButtonPayCourse,
  CardCourse,
  ImageCardCourse,
  InfoCardCourse,
  Section,
} from "../style";
import { useCoursePresenter } from "../../dashboard/instrutor/cursos/presenter";

import { Link, useNavigate, useParams } from "react-router-dom";
import React from "react";
import Loadding from "../../../components/loadding/page";
import {
  Paragraph,
  ParagraphBold,
  Title,
} from "../../../components/Typography";
import Carousel from "react-multi-carousel";
import { Col, Rate, Row } from "rsuite";
import { FaCartShopping, FaLock, FaUser } from "react-icons/fa6";
import theme from "../../../theme";
import TextInput from "../../../components/Inputs/TextInput";
import Button from "../../../components/Buttons/Button";
import { idea } from "../../../images";

import loader from "../../../assets/login.json";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const LoginScreen: React.FC = observer(() => {
  const navigate = useNavigate();
  const { id_categoria } = useParams();

  return (
    <>
      <FrontLayout>
        <Section>
          <Content>
            <AuthForm>
              <Row>
                <Col
                  xl={10}
                  xxl={12}
                  lg={10}
                  md={12}
                  sm={24}
                  smHidden
                  xsHidden
                  xs={24}
                >
                  <ContentImage>
                    <ImageLogin src={idea} />
                  </ContentImage>
                </Col>
                <Col xl={14} xxl={12} lg={14} md={12} sm={24} xs={24}>
                  <Title style={{ marginTop: 10 }}>
                    Entre na sua conta agora .
                  </Title>
                  <Paragraph
                    color="#000"
                    size="15px"
                    style={{ marginTop: 15, marginBottom: 15 }}
                  >
                    Faça login em sua conta para se inscrever em qualquer curso
                    e se tornar parte da nossa comunidade de maneira mais
                    imersiva.
                  </Paragraph>
                  <ParagraphBold style={{ margin: 0, padding: 0 }}>
                    Email
                  </ParagraphBold>
                  <TextInput
                    name="email"
                    placeholder="Email :"
                    icon={
                      <FaUser
                        size={18}
                        color="#999"
                        style={{ margin: 5, marginRight: 12 }}
                      />
                    }
                  />
                  <ParagraphBold style={{ marginTop: 10, padding: 0 }}>
                    Palavra Passe
                  </ParagraphBold>
                  <TextInput
                    placeholder="Palavra Passe :"
                    type="password"
                    name="password"
                    icon={
                      <FaLock
                        size={18}
                        color="#999"
                        style={{ margin: 5, marginRight: 12 }}
                      />
                    }
                  />
                  <Button label="ENTRAR AGORA" color={theme.BACKGROUND.BLUE} />
                </Col>
              </Row>
            </AuthForm>
          </Content>
        </Section>
      </FrontLayout>
    </>
  );
});
export default LoginScreen;
const AuthForm = styled.div`
  width: 100%;
  margin: auto;
  @media ${device.laptopL} {
    width: 80%;
  }
  @media ${device.mobileL} {
    width: 96%;
  }
`;
const ContentImage = styled.div`
  width: 100%;
  items-align: center;
  @media ${device.laptopL} {
    width: 80%;
  }
  @media ${device.mobileL} {
    width: 96%;
  }
`;
const ImageLogin = styled.img`
  width: 75%;
  margin: 10px 4%;
  @media ${device.laptopL} {
    width: 96%;
  }
  @media ${device.mobileL} {
    width: 96%;
  }
`;
const Content = styled.div`
  width: 86%;
  margin: auto;
  @media ${device.laptopL} {
    width: 80%;
  }
  @media ${device.mobileL} {
    width: 96%;
  }
`;
