import { observer } from "mobx-react-lite";

import React, { useRef, useState } from "react";
import { Avatar, Col, InlineEdit, Input, Row, Tabs } from "rsuite";
import { Body, ButtonGroup, CardBody, Container, Content } from "../../style";
import { Paragraph, ParagraphBold } from "../../../../components/Typography";
import TextInputLarge from "../../../../components/Inputs/TextInputLarge";
import { useUserStore } from "../../../../stores/userStore";
import styled from "styled-components";
import Button from "../../../../components/Buttons/Button";
import theme from "../../../../theme";
import { useUserPresenter } from "../presenter";
import { useDebounceEffect } from "../../../../components/canva/useDebounceEffect";
import { canvasPreview } from "../../../../components/canva/previewCanva";
import { MdEmail, MdLock, MdLockPerson, MdMailLock } from "react-icons/md";
import TabConfig from "../../../../components/TabSimple/TabConfig";

const ConfigCount: React.FC = observer(() => {
  const { imgSrc, setImgSrc, loadding, uploadImageProfile } =
    useUserPresenter();
  const { user } = useUserPresenter();
  return (
    <Container>
      <Content>
        <CardBody>
          <Body>
            <TabConfig />
          </Body>
        </CardBody>
      </Content>
    </Container>
  );
});

export default ConfigCount;

const FormWrapper = styled.div`
  margin: 2% 2%;
`;
