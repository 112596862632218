import { observer } from "mobx-react-lite";
import FrontLayout from "../../../layouts/FrontLayout";
import styled from "styled-components";
import { device } from "../../../config/devices";
import { Section } from "../style";
import { Link, useNavigate, useParams } from "react-router-dom";
import React from "react";
import {
  Paragraph,
  ParagraphBold,
  Title,
} from "../../../components/Typography";
import TextInput from "../../../components/Inputs/TextInput";
import { FaLock, FaUser } from "react-icons/fa6";
import Button from "../../../components/Buttons/Button";
import theme from "../../../theme";
import { Col, Row } from "rsuite";
import loader from "../../../assets/signup.json";
import Lottie from "react-lottie";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const SignUpScreen: React.FC = observer(() => {
  const navigate = useNavigate();

  const { id_categoria } = useParams();

  return (
    <>
      <FrontLayout>
        <Section>
          <Content>
            <AuthForm>
              <Row>
                <Col xl={10} xxl={12} lg={10} md={12} sm={24} xs={24}>
                  <Lottie options={defaultOptions} width="100%" />
                </Col>
                <Col xl={14} xxl={12} lg={14} md={12} sm={24} xs={24}>
                  <Title style={{ marginTop: 10 }}>
                    Entre na sua conta agora .
                  </Title>
                  <Paragraph
                    color="#000"
                    size="15px"
                    style={{ marginTop: 15, marginBottom: 15 }}
                  >
                    Crie sua conta agora e tenha acesso a uma experiência de
                    aprendizado online completa e envolvente! 🚀📚
                  </Paragraph>
                  <ParagraphBold style={{ margin: 0, padding: 0 }}>
                    Nome
                  </ParagraphBold>
                  <TextInput
                    placeholder="Email :"
                    icon={
                      <FaUser
                        size={18}
                        color="#999"
                        style={{ margin: 5, marginRight: 12 }}
                      />
                    }
                  />
                  <ParagraphBold style={{ margin: 0, padding: 0 }}>
                    Email
                  </ParagraphBold>
                  <TextInput
                    placeholder="Email :"
                    icon={
                      <FaUser
                        size={18}
                        color="#999"
                        style={{ margin: 5, marginRight: 12 }}
                      />
                    }
                  />
                  <ParagraphBold style={{ marginTop: 10, padding: 0 }}>
                    Palavra Passe
                  </ParagraphBold>
                  <TextInput
                    placeholder="Palavra Passe :"
                    type="password"
                    icon={
                      <FaLock
                        size={18}
                        color="#999"
                        style={{ margin: 5, marginRight: 12 }}
                      />
                    }
                  />
                  <ParagraphBold style={{ marginTop: 10, padding: 0 }}>
                    Palavra Passe
                  </ParagraphBold>
                  <TextInput
                    placeholder="Palavra Passe :"
                    type="password"
                    icon={
                      <FaLock
                        size={18}
                        color="#999"
                        style={{ margin: 5, marginRight: 12 }}
                      />
                    }
                  />
                  <Button label="ENTRAR AGORA" color={theme.BACKGROUND.BLUE} />
                </Col>
              </Row>
            </AuthForm>
          </Content>
        </Section>
      </FrontLayout>
    </>
  );
});
export default SignUpScreen;
const Content = styled.div`
  width: 86%;
  margin: auto;
  @media ${device.laptopL} {
    width: 80%;
  }
  @media ${device.mobileL} {
    width: 96%;
  }
`;

const AuthForm = styled.div`
  width: 100%;
  margin: auto;
  @media ${device.laptopL} {
    width: 80%;
  }
  @media ${device.mobileL} {
    width: 96%;
  }
`;
