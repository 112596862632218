import { createClient, SupabaseClient } from "@supabase/supabase-js";
import { injectable } from "inversify";
import { v4 as uuidv4 } from "uuid";
@injectable()
class SupabaseGateway {
  sbClient!: SupabaseClient;
  constructor() {
    if (!this.sbClient) {
      this.sbClient = createClient(
        "https://ijgwjowwhfqbsqzsauwv.supabase.co",
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImlqZ3dqb3d3aGZxYnNxenNhdXd2Iiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY4OTU4Mjc0OSwiZXhwIjoyMDA1MTU4NzQ5fQ.i4F3h-qF6UJj9Gj84Y7TzCrflyZ5GAOJHQFEmJ6Eq14"
      );
    }
  }

  async insertToTable<T>(
    table: string,
    payload: T
  ): Promise<{ data: T; error: any }> {
    return Array.isArray(payload)
      ? ((await this.sbClient.from(table).insert(payload)) as unknown as {
          data: T;
          error: any;
        })
      : ((await this.sbClient.from(table).insert([payload])) as unknown as {
          data: T;
          error: any;
        });
  }
  async uploadFile<T>(file: File, storage: string) {
    const filename = uuidv4();
    const { data, error } = await this.sbClient.storage
      .from(storage)
      .upload(filename, file, {
        cacheControl: "3600",
        upsert: false,
      });
    const { publicUrl } = await this.getUrlPublic(filename, storage);
    return { publicUrl };
  }
  async getUrlPublic(filename: string, storage: string) {
    const { data } = await this.sbClient.storage
      .from(storage)
      .getPublicUrl(filename);
    return data;
  }
  async updateTable<T>(
    table: string,
    payload: T,
    match: any
  ): Promise<{ data: T; error: any }> {
    return (await this.sbClient
      .from(table)
      .update(payload)
      .match(match)) as unknown as {
      data: T;
      error: any;
    };
  }
  async getOneById(
    table: string,
    fields = "*",
    id: string | bigint | number,
    column: string
  ) {
    return this.sbClient.from(table).select(fields).eq(column, id).single();
  }
  async getOneId(uuid: string, table: string) {
    return this.sbClient.from(table).select("id").eq("uuid", uuid).single();
  }

  async selectFromTableWithFilter(
    table: string,
    fields = "*",
    filter: {
      column: string;
      operator: any;
      value: string;
    }
  ) {
    return await this.sbClient
      .from(table)
      .select(fields)
      .eq(filter.column, filter.value)
      .order("id", { ascending: true });
  }
  async getOneByColumns(
    table: string,
    column1: string,
    column2: string,
    value1: string,
    value2: string
  ) {
    return this.sbClient
      .from(table)
      .select()
      .eq(column1, value1)
      .eq(column2, value2)
      .single();
  }
  async selectFromTableWithFilterColumns(
    table: string,
    filter: {
      column1: string;
      column2: string;
      operator: any;
      value1: string;
      value2: string;
    }
  ) {
    return await this.sbClient
      .from(table)
      .select()
      .eq(filter.column1, filter.value1)
      .eq(filter.column2, filter.value2);
  }
  async selectFromTable<T>(table: string, fields = "*") {
    return await this.sbClient
      .from(table)
      .select(fields)
      .order("id", { ascending: false });
  }
  async deleteFromTable(table: string, column: string, value: string) {
    return await this.sbClient.from(table).delete().eq(column, value);
  }
}

export default SupabaseGateway;
