export enum Tables {
  users = "users",
  cursos = "cursos",
  quetions = "quetions",
  aprendizados = "aprendizados",
  modulos = "modulos",
  items_module = "items_module",
  purchased_courses = "purchased_courses",
  answers = "answers",
  video_viewed = "video_viewed",
  rate_lesson = "rate_lesson",
}
