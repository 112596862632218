import { inject, injectable } from "inversify";
import { makeAutoObservable, runInAction } from "mobx";
import { makePersistable } from "mobx-persist-store";
import SupabaseGateway from "../gateways/SupabaseGateway";
import { Tables } from "../types/enums";
import { useClassStore } from "../util/useClassStore";
import container from "./ioc";
import UIStore, { AlertType } from "./uiStore";
interface IRATE_FILTER {
  course_id?: string;
  user_id?: string;
}
@injectable()
class RateStore {
  @inject(SupabaseGateway) private supabaseGateway!: SupabaseGateway;
  @inject(UIStore) ui!: UIStore;
  rates!: IRATE_LESSON[];
  rateSaved = false;
  constructor() {
    makeAutoObservable(this);
  }
  createRate = async (rate: IRATE_LESSON) => {
    try {
      const { data, error } =
        await this.supabaseGateway.insertToTable<IRATE_LESSON>(
          Tables.rate_lesson,
          rate
        );
      runInAction(() => {
        this.rateSaved = true;
      });
      if (error) throw new Error(error.message);
      this.ui.showAlert("Avaliação enviada com sucesso...", AlertType.success);
    } catch (error: any) {
      this.ui.showAlert(error.message, AlertType.error);
    }
  };
  getRateLessonByUser = async (lesson: string, user_id: string) => {
    try {
      const { data, error } = await this.supabaseGateway.getOneByColumns(
        Tables.rate_lesson,
        "user_id",
        "lesson",
        user_id as string,
        lesson as string
      );
      if (error) throw new Error(error.message);
      return data;
    } catch (error: any) {}
  };
}
export const useRateStore = () =>
  useClassStore<RateStore>(container.get(RateStore));

export default RateStore;
